import React from 'react'
import { openToday } from '@zupr/utils/hours'
import { Location } from '@zupr/types/fo'

import Trans from '../../components/trans'

interface NowOpenProps {
    time?: boolean
    opening_hours?: Location['opening_hours']
}

const NowOpen = ({ time, opening_hours }: NowOpenProps) => {
    if (!opening_hours || !Object.keys(opening_hours).length) {
        return <Trans label="Opening hours unknown" context="opening-hours" />
    }

    const today = openToday(opening_hours)

    if (today.now) {
        if (!time) {
            return <Trans label="Now open" />
        }
        return (
            <React.Fragment>
                <Trans label="Opening hours today" context="opening-hours" />
                {': '}
                <span className="time">{`${today.opening} - ${today.closing}`}</span>
            </React.Fragment>
        )
    }

    if (today.later) {
        if (!time) {
            return <Trans label="Open later today" context="opening-hours" />
        }
        return (
            <React.Fragment>
                <Trans label="Opening hours today" context="opening-hours" />
                {': '}
                <span className="time">{`${today.opening} - ${today.closing}`}</span>
            </React.Fragment>
        )
    }

    if (today.hours) {
        if (!time) {
            return <Trans label="Closed" context="opening-hours" />
        }
        return (
            <React.Fragment>
                <Trans label="Opening hours today" context="opening-hours" />
                {': '}
                <span className="time">
                    <Trans label="Closed" context="opening-hours" />
                </span>
            </React.Fragment>
        )
    }

    if (!time) {
        return <Trans label="Closed" context="opening-hours" />
    }

    return (
        <React.Fragment>
            <Trans label="Opening hours today" context="opening-hours" />
            {': '}
            <span className="time">
                <Trans label="Closed" context="opening-hours" />
            </span>
        </React.Fragment>
    )
}

export default NowOpen
